import React, { useEffect, useMemo, useState } from 'react';
import BigNumber from 'bignumber.js';
import { Trans } from '@lingui/macro';

import { useModalContext } from '../../../hooks/useModal';
import { usePoolDataQuery } from '../../../hooks/yldr/usePoolDataQuery';
import { calculateEstimateYearlyFees } from '../../../utils/calculateEstimateYearlyFees';
import { getAmountToBorrow } from '../../../utils/getAmountToBorrow';
import { getBorrowApyByAmount } from '../../../utils/getBorrowApyByAmount';
import { useLeveragePositionValues } from '../../../hooks/yldr/useLeveragePositionValues';
import { useGlobalRevenueFeesQuery } from '../../../hooks/yldr/useGlobalRevenueFeesQuery';
import { AlertBanner } from '../../../ui-kit/AlertBanner';
import { DEFAULT_SLIPPAGE_OPTIONS } from '../../../ui-config/networksConfig';
import { SelectSlippage } from '../../../components-yldr/SelectSlippage';
import { ComputedReserveData } from '../../../hooks/app-data-provider/useAppDataProvider';
import { ScrollModalContent, ScrollModalFooter } from '../../primitives/ScrollModal';
import { PositionModalWrapperProps } from '../FlowCommons/PositionModalWrapper';
import { TxSuccessView } from '../FlowCommons/Success';
import { DetailsNumberLine, TxModalDetails } from '../FlowCommons/TxModalDetails';
import { PositionWidget } from '../FlowCommons/PositionWidget';
import { AssetToBorrow } from '../FlowCommons/AssetToBorrow';
import { LeveragePositionActions } from './LeveragePositionActions';
import { MIN_LEVERAGE } from './constants';

export const LeveragePositionModalContent = React.memo(
  ({ isWrongNetwork, enrichedPosition, tokenId, tokenA, tokenB }: PositionModalWrapperProps) => {
    const [slippage, setSlippage] = React.useState(DEFAULT_SLIPPAGE_OPTIONS[0]);
    const [leverage, setLeverage] = useState(MIN_LEVERAGE);
    const [tokenToBorrow, setTokenToBorrow] = useState<ComputedReserveData>();
    const { gasLimit, txError, mainTxState, setTxError } = useModalContext();
    const { data: globalRevenueFees } = useGlobalRevenueFeesQuery();
    const { data: poolData } = usePoolDataQuery({
      marketId: enrichedPosition.marketId,
      token0: enrichedPosition.token0.underlyingAsset,
      token1: enrichedPosition.token1.underlyingAsset,
      feeAmount: enrichedPosition.feeAmount,
    });

    const amountToBorrow = getAmountToBorrow(enrichedPosition, tokenToBorrow, leverage);

    const positionValues = useLeveragePositionValues({
      liquidityTokenA: enrichedPosition.token0.liquidity,
      liquidityTokenB: enrichedPosition.token1.liquidity,
      marketReferenceCurrencyValue: enrichedPosition.marketReferenceCurrencyValue,
      priceInMarketReferenceCurrencyA: enrichedPosition.token0.priceInMarketReferenceCurrency,
      priceInMarketReferenceCurrencyB: enrichedPosition.token1.priceInMarketReferenceCurrency,
      leverage,
    });

    const detailsValues = useMemo(() => {
      const totalBorrowed = Math.round(enrichedPosition.marketReferenceCurrencyValue * (leverage - 1) * 10000) / 10000;
      const borrowApy = getBorrowApyByAmount({ amountToBorrow, tokenToBorrow });
      const LTV = 100 * totalBorrowed / positionValues.totalAmountUsd;
      const healthFactor = positionValues.totalAmountUsd * enrichedPosition.liquidationThreshold / totalBorrowed;
      return {
        totalBorrowed,
        borrowApy,
        LTV,
        healthFactor
      }
    }, [enrichedPosition.tokenId, leverage]);

    const leveragePositionActionsProps = {
      marketId: enrichedPosition.marketId,
      slippage: BigNumber(slippage).multipliedBy(100).toNumber(),
      isWrongNetwork,
      tokenId,
      leverage,
      amountToBorrow,
      tokenToBorrow,
    }

    const estimateYearlyFees = calculateEstimateYearlyFees(
      poolData,
      BigNumber(enrichedPosition.liquidity.toString()).multipliedBy(leverage),
    );

    const netWorthUsd = enrichedPosition.token0.netWorthUsd + enrichedPosition.token1.netWorthUsd;

    const estApr = estimateYearlyFees.div(netWorthUsd).multipliedBy(100);
    const globalRevenueFee = globalRevenueFees.find((item) => {
      return item.marketId === enrichedPosition.marketId;
    })?.revenueFee;
    const revenueFee = estApr
      .multipliedBy(globalRevenueFee || 0)
      .div(10000)
      .multipliedBy(leverage - 1)
      .div(leverage);
    const borrowApy = BigNumber(detailsValues.totalBorrowed)
      .multipliedBy(detailsValues.borrowApy)
      .dividedBy(100)
      .dividedBy(netWorthUsd)
      .multipliedBy(100);

    const estNetApr = enrichedPosition.positionInRange
      ? estApr.minus(revenueFee).minus(borrowApy).toNumber()
      : undefined;

    // const addToken: ERC20TokenType = {
    //   address: poolReserve.yTokenAddress,
    //   symbol: poolReserve.iconSymbol,
    //   decimals: poolReserve.decimals,
    //   aToken: true,
    // };

    useEffect(() => {
      const usdxToken = tokenA.symbol.toLowerCase().includes('usd')
        ? tokenA
        : tokenB;
      setTokenToBorrow(usdxToken)
    }, [enrichedPosition.tokenId]);

    if (mainTxState.success)
      return (
        <ScrollModalContent>
          <TxSuccessView
            action={<Trans>Leveraged position</Trans>}
            amount={enrichedPosition.marketReferenceCurrencyValue.toString()}
            symbol={`${enrichedPosition.token0.symbol} / ${enrichedPosition.token1.symbol}`}
            // addToken={addToken}
          />
        </ScrollModalContent>
      );

    return (
      <>
        <ScrollModalContent>
          <AssetToBorrow
            tokenA={tokenA}
            tokenB={tokenB}
            leverage={leverage}
            setLeverage={setLeverage}
            tokenToBorrow={tokenToBorrow}
            estNetApr={estNetApr}
            setTokenToBorrow={setTokenToBorrow}
          />
          <TxModalDetails
            header={(
              <PositionWidget
                symbolToken0={enrichedPosition.token0.symbol}
                symbolToken1={enrichedPosition.token1.symbol}
                {...positionValues}
              />
            )}
            gasLimit={gasLimit}
            skipLoad={true}
            bottomSlot={(
              <SelectSlippage defaultOptions={DEFAULT_SLIPPAGE_OPTIONS} value={slippage} onChange={setSlippage} />
            )}
          >
            <DetailsNumberLine
              description={<Trans>Total Borrowed</Trans>}
              value={detailsValues.totalBorrowed}
              numberPrefix="$"
            />
            <DetailsNumberLine
              description={<Trans>Borrow APY</Trans>}
              value={detailsValues.borrowApy}
              postfix="%"
            />
            <DetailsNumberLine
              description={<Trans>Health Factor</Trans>}
              value={detailsValues.healthFactor}
            />
            <DetailsNumberLine
              description={<Trans>Initial LTV</Trans>}
              value={detailsValues.LTV}
              postfix="%"
            />
            <DetailsNumberLine
              description={<Trans>Liquidation threshold</Trans>}
              value={100 * enrichedPosition.liquidationThreshold}
              postfix="%"
            />
            <DetailsNumberLine
              description={<Trans>Liquidation penalty</Trans>}
              value={100 * enrichedPosition.liquidationBonus - 100}
              postfix="%"
            />
          </TxModalDetails>

          {txError && (
            <AlertBanner variant="error" txError={txError} onClose={() => setTxError(undefined)} />
          )}

        </ScrollModalContent>

        <ScrollModalFooter>
          <LeveragePositionActions {...leveragePositionActionsProps} />
        </ScrollModalFooter>
      </>
    );
  }
);
